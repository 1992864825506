import React, { type MouseEvent, useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Image from 'Components/common/image';

import { strings } from 'Constants/strings';

import {
	CityCountryName,
	CityDisplayName,
	StyledCityCardContainer,
} from './styles';
import type { TCityCardV2Props } from './types';

const CityCard: React.FC<TCityCardV2Props> = ({
	city,
	className = '',
	pageHref = '',
	onClick,
	showDiscoverableOnly = true,
}) => {
	const router = useRouter();

	const handleClick = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (pageHref) {
				router.push(pageHref);
			}
			onClick?.(e);
		},
		[router, onClick, pageHref],
	);

	if (!city || (showDiscoverableOnly && !city?.discoverable)) return null;

	const {
		imageURL,
		displayName,
		country: { displayName: countryDisplayName },
	} = city;

	const displayText = strings.formatString(
		strings.THINGS_TO_DO_IN_CITY,
		displayName,
	);

	return (
		<StyledCityCardContainer
			onClick={handleClick}
			className='city-card-wrapper block'
		>
			<div className={`city-card-v2-wrapper ${className}`}>
				<div className='city-card'>
					<div className='city-image-wrapper'>
						<Image
							className='city-image'
							src={imageURL}
							width={180}
							height={180}
							alt={displayName}
						/>
					</div>
					<div className='city-bottom-wrapper'>
						<div className='city-title'>
							<Link href={pageHref} aria-label={displayText}>
								<CityDisplayName
									className='city-display-name'
									onClick={e => e.stopPropagation()}
								>
									{displayText}
								</CityDisplayName>
							</Link>
						</div>
						<div className='city-tag-line'>
							<CityCountryName className='city-country-name'>
								{countryDisplayName}
							</CityCountryName>
						</div>
					</div>
				</div>
			</div>
		</StyledCityCardContainer>
	);
};

export default CityCard;
